.features-catalogue-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Raleway" !important;
}

.features-catalogue-list-li {
    display: table;
}

.features-catalogue-list-exclusive-li{
    display: table;
}

.features-catalogue-list-exclusive-li::before{
    content: "\25C6";
    color: #0EAEDE;
    font-weight: bold;
    display: inline-block;
    width: 2.5em;
    font-size: 1.5rem;
    line-height: 2rem;
    vertical-align: middle;
}

.features-catalogue-list-li::before {
    content: "\2022";
    color: #1E396B;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 4rem;
    line-height: 2rem;
    vertical-align: middle;
}

.features-catalogue-list-item {
    display: table-cell;
    vertical-align: middle;
    color: #000000;
    font-weight: 600;
    cursor:pointer;
}

.list-active-name {
    font-weight: 700;
}

.carousel-feature-text {
    text-align: left;
    font-size: 0.7rem;
    color: #000000;
    font-family: 'Raleway';
    font-weight: 600;
    margin-top: 1rem;
    margin-left: 1rem;
}

.carousel-feature-image {
    width: 100%;
    border: 10px solid black;
    border-radius: 10px;
}

.features-catalogue-carousel .slick-dots {
    left: 100%;
    top: 0;
    margin-left: 1rem;
    width: 0;
}

.features-catalogue-carousel .slick-dots li {
    display: list-item;
}

.features-catalogue-carousel .slick-dots li button:before {
    content: '\25A0';
    color: #BBD3F3;
    font-size: 1.5rem;
    opacity: 0.5;

}

.features-catalogue-carousel .slick-dots li.slick-active button:before {
    color: #1E396B;
    opacity: 1;
}

.features-exclusive-text{
    color: #0EAEDE;
    font-weight: 600;
    font-size: 1rem;
}

//mobile

.carousel-feature-image-mobile {
    width: 90%;
    border: 10px solid black;
    border-radius: 10px;
}

.features-catalogue-carousel-mobile .slick-prev:before, .features-catalogue-carousel-mobile .slick-next:before {
    font-family: 'Font Awesome 5 Free', "slick", sans-serif;
    font-weight: 500;
    font-size: 35px;
    color: black;
}
.features-catalogue-carousel-mobile .slick-prev, .features-catalogue-carousel-mobile .slick-next{
    top:35%
}
.features-catalogue-carousel-mobile .slick-prev:before { content: "\f053"; }
.features-catalogue-carousel-mobile .slick-next:before { content: "\f054"; }

.carousel-feature-text-mobile {
    text-align: center;
    font-size: 0.8rem;
    color: #000000;
    font-family: 'Raleway';
    font-weight: 600;
    margin-top: 1rem;
    margin-left: 1rem;
}

.carousel-feature-text-title-mobile{
    font-size: 1rem;
}

.features-catalogue-carousel-mobile .slick-dots li button:before {
    font-size: 0.8rem;
    opacity: 0.5;
    color: #C1C1C1;

}

.features-catalogue-carousel-mobile .slick-dots li.slick-active button:before {
    color: #1E396B;
    opacity: 1;
}
