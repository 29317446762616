.webp .footer-partial-background {
    background-image: url("/images/footer-image.webp");
    background-position: 56% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-webp .footer-partial-background {
    background-image: url("/images/footer-image.jpg");
    background-position: 56% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-color-white {
    color: #fff !important;
}

.button-contact {
    padding: 0.75rem 2.5rem;
    font-size: 0.875rem;
    border-color: #ccc;
    white-space: nowrap;
    background-color: $button-color;
    color: #fff !important;
}

.button-contact:hover {
    background-color: black !important;
    opacity: 1;
}

.button-partner {
    background-color: #fff !important;
    color: black !important;
    padding: 0.75rem 2.5rem;
    font-size: 0.875rem;
    border-color: #000000;
    white-space: nowrap;
}

.button-partner:hover {
    background-color: rgb(34,38,43) !important;
    color: white !important;
    border-color: #fff;
}

.modal-footer{
    border-top: none;
}

.footer-title {
    font-size: 1.625rem !important;
}

.footer-partial-style {
    line-height: 1rem;
}

@media (max-width: 768px){
    .text-style-partial-footer{
        text-align: center;
    }
    .button-footer-margin {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .button-custom-margin {
        margin-left: 0!important;
    }
}

@media (max-width: 878px) {
    .button-contact {
        padding: 0.6rem 2.5rem;
    }
    .button-partner{
        padding: 0.75rem 2.5rem;
    }
}

@media (max-width: 992px) {
    .button-custom-margin {
        margin-left: 1.875rem;
    }
}

