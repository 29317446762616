.h3-testimonials-text-style {
    font-size: 1rem;
    color: #444444;
    font-family: 'Raleway';
}

.span-testimonials-text-style {
    font-size: 0.75rem;
    color: #888888;
    font-weight: 300;
}

.logo-border{
    border: 1px solid ;
    align-self: center;
    color: #e5e5e5;
    max-width: 90%;
}
