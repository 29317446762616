
//Navigation bar

.navbar-brand {
    display: contents;
}

.navbar-logo {
    max-width: 11%;
}

.nav-items-margin {
    margin-left: 6.5rem;
}

.nav-items-margin-ro {
    margin-left: 11rem;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(253,253,254, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    background-color: #1e396b;
    padding-left: 2.5rem;
    padding-top: 2.0rem;
    transition: all 0s linear 0s;
}

.custom-toggler.navbar-toggler {
    border-color: white;
}

.nav-font {
    font-family: "Source Sans Pro";
}

.custom-language-selector{
    width: fit-content;
    border: none;
    line-height: 2rem;
    transition: all 0s linear 0s;
}

.button-center{
    padding: 0.7rem 1rem;
}

@media (min-width: 1200px){
    .nav-item {
        padding-right: 1.65rem;
    }
    .nav-logo-style {
        margin-left: -1.25rem;
    }
    .nav-logo-style-ro {
        margin-left: -1.7rem;
    }
}
.nav-style {
    position: relative;
    color: rgba(0, 0, 0, 0.9) !important;
}

.nav-style:hover {
    color: #83479b !important;
}

.nav-style::after {
    position: absolute;
    content: "";
    height: .15em;
    top: 100%;
    background: #cb68a8 !important;
    z-index: -1;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: transform .5s cubic-bezier(.95, .05, .795, .035);
}

.nav-style:hover::after {
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transform: scaleX(1);
}

.active {
    position: relative;
    color: #83479b !important;
}

.active::after{
    position: absolute;
    content: "";
    height: .15em;
    top: 100%;
    background: #cb68a8 !important;
    z-index: -1;
    left: 0;
    right: 0;
}

.demo-link-padding {
    padding-top: 0.2rem;
}

.free-demo-btn {
    color: #FFFFFF !important;
    background-color: #cb68a8;
    padding: 3px 10px;
    border-radius: 50px;
}

.free-demo-btn:hover {
    background-color: black !important;
    opacity: 1;
}

.nav-subtext-style {
    font-weight: 600;
    font-family: Raleway;
}

@media (max-width: 992px){
    .navbar-logo {
        max-width: 24%;
    }
    .navbar-border {
        border-bottom: 1px solid #e7e4ef;
    }
    .nav-items-margin{
        margin-left: 0;
    }
    .nav-items-margin-ro {
        margin-left: 0;
    }
    .nav-style:hover::after{
        content: none;
    }
    .active::after{
        content: none;
    }
    .free-demo-btn{
        width: fit-content;
        padding-right: 0.3rem !important;
        padding-left: 0.3rem !important;
        margin-top: 0.3rem !important;
    }
    .nav-style-border{
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
    .sticky-top{
        position: relative;
    }
    .language-selector-padding {
        margin-left: -1.5rem !important;
    }

    .nav-text-style {
        line-height: 1.5625rem;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9375rem !important;
    }

    .nav-subtext-style {
        color: #1e396b;
        font-weight: 600;
        line-height: 1.5625rem;
        font-size: 0.9375rem !important;
        white-space: normal;
    }

    .demo-link-padding {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
    }
    .sub-menu-padding {
        padding: 0.5rem 1rem;
    }

    .menu-padding-top{
        padding-top: 1.5rem;
    }
}

.nav-text-style {
    font-size: 0.7rem;
    font-family: Raleway;
    font-weight: 700;
}

.custom-moving-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    transition: all 300ms linear 0ms !important;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
}

.language-selector-padding {
    margin-left: -0.9375rem;
}

@media (min-width: 992px) {
    .dropdown:hover .dropdown-menu {
        display: block;
        min-width: 0;
    }

    .custom-navbar-margins {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
}

