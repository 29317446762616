//h3
.h3-heading-style-text {
    font-size: 1.5rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-about-style-text {
    font-size: 1.5rem;
    color: white;
    font-weight: 400;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h4-about-style-text {
    font-size: 1.125rem;
    color: white;
    font-weight: 400;
    font-family: "Raleway" !important;
}

.h4-about-team-style-text {
    font-size: 1.125rem;
    color: #444444;
    font-weight: 400;
    font-family: "Raleway" !important;
}

.pAbout {
    font-size: 0.75rem;
    color: #888888;
    font-weight: 400;
    line-height: 0.875rem;
    margin-bottom: 0 !important;
}

.pAboutTitle {
    font-size: 1.125rem;
    color: #444444;
    font-weight: 600;
    line-height: 1.5rem;
}

.webp .backAbout {
    background-image: url("/images/aboutBackground.webp");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-webp .backAbout {
    background-image: url("/images/aboutBackground.jpg");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.backDark {
    background-color: #22262b;
}

.paddingTopAbout {
    padding-top: 6%;
}

@media (max-width: 991.98px) {
    .pAbout {
        font-size: 0.55rem !important;
    }

    .pAboutTitle {
        font-size: 1rem !important;
    }

    .webp .backAbout {
        background-image: url("/images/backgroundGray.webp");
        height: auto;
        padding-bottom: 3rem;
    }

    .no-webp .backAbout {
        background-image: url("/images/backgroundGray.jpg");
        height: auto;
        padding-bottom: 3rem;
    }
}

@media (max-width: 778px){
    .images-padding-left {
        padding-left: 0;
    }

    .images-padding-right {
        padding-right: 0;
    }
}
