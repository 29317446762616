.link-decoration,.link-decoration:hover,.link-decoration:focus {
    color: $link-color;
    text-decoration: none;
    outline: none;
}

.no-webp .contactBack {
    background-image: url("~/images/background-wall.jpg");
}

.webp .contactBack {
    background-image: url("~/images/background-wall.webp");
}

.contact-subheading-style{
    font-size: 0.9375rem;
    font-weight: 400;
    color: #888 !important;
}

.contact-page-text-style {
    letter-spacing: 0.035em;
    color: #444;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 	0.625rem !important;
}

.border-bottom-title{
    position:relative;
}

.border-bottom-title::after{
    border-bottom: 1px solid #444;
    bottom: 0;
    content: " ";
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    width: 50%;
    transform: translate(-50%, -50%);
    background-color: #444;
}

@media (max-width: 991.98px) {
    .contact-subheading-style {
        font-size: 0.9375rem !important;
    }
}

.contact-info-text {
    padding:0 2rem;
}

@media (max-width: 1200px) {
    .contact-info-text {
        padding:0 1rem 1rem 2rem;
        max-width:100%;
        
    }
}


@media (max-width: 400px) {
    .contact-info-text {
        font-size: 0.75rem;
    }
}
