.webp .backDemo {
    background-image: url("/images/background-wall.webp");
}

.no-webp .backDemo {
    background-image: url("/images/background-wall.jpg");
}

.custom-list {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
        text-indent: -1em;
}

ul.custom-list li span {
    position: relative;
    left: 1rem;
}

.title-line-space-bottom {
    position: relative;
    padding-bottom: 30px;
}

.line-bottom-left .title-line-space-bottom::after {
    width: 30px;
    border-top: 4px solid #1e396b !important;
    position: absolute;
    content: "";
    margin: auto;
    padding-top: 0;
    right: inherit;
    left: 0;
    bottom: 0;
    top: inherit;
}

.heading-margin {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
}

.page-custom-heading-style-text {
    font-size: 	0.9375rem;
    color: #888 !important;
}


.demo-border{
    border-left: 1px dashed #e5e5e5;
}

.demo-form{
    padding-left: 5rem;
    padding-right: 5rem;
}

.demo-heading-style-text{
    font-size: 2rem;
    font-weight: 600;
    color: #1f3e6f;
    letter-spacing: 0.035em;
}

.dc-logo{
    max-width: 40%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.dc-image{
    max-width: 70%;
    margin-bottom: 2rem;
}

.demo-text{
    font-size: 1.1rem;
}

@media (max-width: 768px) {
    .demo-border{
        border: none;
    }

    .demo-form{
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .dc-logo{
        max-width: 60%;
    }

}



//h3, p {
//    text-align: center;
//}

//.form-group {
//    margin: 30px;
//}

.file-upload .form-control {
    height: 100px;
    outline: 1px dashed #25b5e3;
}
//.file-upload .form-control:before {
//    content: "\f093";
//    font: normal normal normal 14px/1 FontAwesome;
//    font-size: 3em;
//    left: 0;
//    right: 0;
//    display: block;
//    margin: 20px auto;
//    text-align: center;
//}
.demo-file-upload{
    border: none;
}

.file-upload .form-control:after {
    content: attr(data-message);
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: block;
    position: relative;
    top: 45%;
    white-space: pre-wrap;
}

.added-text{
    display: block;
    position: relative;
    top: 35%;
    bottom: 0;
    text-align: center;
    color: #939393;
}

.demo-icon{
    font-style: normal;
    color: #25b5e3;
}
.added-text-second{
    font-size: 0.7rem;
}

.file-upload .form-control input[type="file"] {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
body.dragged .file-upload .form-control input[type="file"] {
    /* Make sure it is full screen, whatever the position absolute container */
    position: fixed;
    top: -50vh;
    bottom: -50vh;
    left: -50vw;
    right: -50vw;
    height: 200vh;
    width: 200vw;
    z-index: 10002;
}

body:after {
    content: 'You can drop the file.';
    font-size: 2em;
    text-align: center;
    line-height: 100vh;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: #eee;
    z-index: 10000;
    border-radius: 4px;
    border: thin solid #ccc;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease;
}

body.dragged:after {
    opacity: 1;
    visibility: visible;
}
