.essential_features_name{
    font-size: 	1.125rem;
    color: #444;;
    font-family: Raleway;
    letter-spacing: 0.035em;
    line-height: 1.2em;
    font-weight: 600;
}
.essential_features_body{
    font-size: 	0.9375rem;
    color: #888;
}
.essential-feature-border{
    border-bottom: 1px solid #eee;
}

.custom-feature-picture{
    margin-top: -15px;
}

.features_top_margin{
    margin-top: 1.6rem;
}


@media (min-width: 698px) and (max-width: 767px) {
    .essential-feature-border {
        padding: 3.2rem;
    }
}
