.no-webp .backDevelopment {
    background-image: url("/images/webDevBackground.jpg");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.webp .backDevelopment {
    background-image: url("/images/webDevBackground.webp");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.backBlack {
    background-color: black;
}

.paddingTop-development {
    padding-top: 8%;
}

.dev-title-style{
    font-family: 'Raleway';
    font-size: 0.875rem;
    font-weight: 700;
    color: #444;
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.25rem;
    margin-bottom: 	3.125rem;
    padding-left: 	3.4375rem !important;
}

@media (min-width: 1200px) and (max-width: 1540px) {
    .dev-title-style {
        font-size: calc(0.7vw);
    }
}



.dev-subtext-style{
    font-size: 0.9375rem;
    font-weight: 400;
    color: #888 !important;
}

.custom-span {
    color: #444;
    background: #fff;
    border: 1px solid #ddd;
    font-size: 1.5rem;
    height: 3.75rem;
    left: 0;
    line-height: 3.688rem;
    position: absolute;
    text-align: center;
    top: 0.4375rem;
    width: 3.75rem;
    border-radius: 100%;
    transition: 1s all ease;
}

.span-text-size {
    font-size: 	1.875rem;
    line-height: 3.688rem !important;
}

.h3-web-dev-style {
    font-size: 1.3rem;
    color: white;
    font-weight: 400;
}

.custom-span:hover {
    background-color: #1e396b;
    color: #fff;
}

.custom-span:hover + .dev-title-style {
    border-bottom: 1px solid #1e396b;
    transition: 1s all ease;
}

@media (min-width: 768px) {
    .dev-title-style.line-top.line-top-sm {
        padding-bottom: 0.3rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .developmentH3Ro {
        height: 107px;
    }
}

@media (min-width: 992px) {
    .developmentH3 {
        height: 80%;
    }
}

@media (min-width: 992px) and (max-width: 1072px) {
    .dev-title-style.line-top.line-top-lg {
        padding-bottom: 0.3rem;
    }
}

@media (max-width: 768px) {
    .h3-web-dev-style {
        font-size: 1rem;
    }
}

@media (max-width: 991.98px) {
    .dev-title-style{
        font-size: 0.8rem;
    }
    .webp .backDevelopment {
        background-image: url("/images/backgroundGray.webp");
        height: auto;
        padding-bottom: 3rem;
    }

    .no-webp .backDevelopment {
        background-image: url("/images/backgroundGray.jpg");
        height: auto;
        padding-bottom: 3rem;
    }

}

@media (min-width: 1200px) {
    .dev-title-style.line-top.line-top-lg {
        padding-bottom: 1.25rem;
    }
}
