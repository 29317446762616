.error-main-title {
    padding-bottom: 1rem;
    font-weight: bold;
}

.error-margin {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

@media  (max-width: 990px) {
    .error-main-title{
        font-size: 1.75rem !important;
    }
    .error-sub-title {
        font-size: 1.375rem !important;
    }

    .error-margin {
        margin-top: 0;
        margin-bottom: 0;
    }
}

