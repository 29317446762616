//h3
.h3-heading-style-text {
    font-size: 1.5rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-catalogue-style-text {
    font-size: 1.25rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-catalogue-box-style-text {
    font-size: 0.875rem;
    color: #22262B;
    font-weight: 700;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-retail-style-text {
    font-size: 1.125rem;
    color: #444444;
    font-weight: 400;
    letter-spacing: 0.035em;
}
//price cards
.h4-catalogue-card-style-text {
    font-size: 1.125rem;
    color: #444444;
    font-weight: 400;
    font-family: "Raleway" !important;
}

.h4-catalogue-style-text {
    font-size: 1.375rem;
    color: #444444;
    font-weight: 400;
    font-family: "Raleway" !important;
}

.li-catalogue-text-style {
    font-size: 0.875rem;
    color: #888888;
    font-weight: 400;
}

.li-retail-text-style, .li-b2b-text-style, .li-corporate-text-style, .li-media-text-style {
    font-size: 0.9375rem;
    color: #888888;
    font-weight: 400;
}

.retail-list-font {
    font-size: 0.9375rem;
}

.retail-list-margin {
    margin-bottom: 	0.625rem;
}
.retail-list-check {
    color: #83479b !important;
}

.interactive-bottom-font {
    font-size: 1.125rem !important;
}

.retail-list-text-margin {
    margin-left: 0.625rem;
}

.aRetail {
    font-size: 1.375rem;
    text-decoration: none !important;
    color: #444444;
}

.backCatalogue, .backCatalogueRetail, .backCatalogueB2B, .backCatalogueCorporate, .backCatalogueMedia  {
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-webp .backCatalogue {
    background-image: url("/images/catalogueBackground.jpg");
}

.webp .backCatalogue {
    background-image: url("/images/catalogueBackground.webp");
}

.no-webp .backCatalogueRetail {
    background-image: url("/images/catalogueRetailBackground.jpg");
}

.webp .backCatalogueRetail {
    background-image: url("/images/catalogueRetailBackground.webp");
}

.no-webp .backCatalogueB2B {
    background-image: url("/images/catalogueB2BBackground.jpg");
}

.webp .backCatalogueB2B {
    background-image: url("/images/catalogueB2BBackground.webp");
}

.no-webp .backCatalogueCorporate {
    background-image: url("/images/catalogueCorporateBackground.jpg");
}

.webp .backCatalogueCorporate {
    background-image: url("/images/catalogueCorporateBackground.webp");
}

.no-webp .backCatalogueMedia {
    background-image: url("/images/catalogueMediaBackground.jpg");
}

.webp .backCatalogueMedia {
    background-image: url("/images/catalogueMediaBackground.webp");
}

.backGreyCol {
    background-color: #f5f5f5;
}

.paddingTopCatalogue {
    padding-top: 5%;
}

.paddingTopB2B{
    padding-top: 17%;
}

.btnPink {
    background-color: rgb(202, 101, 166) !important;
    border-color: transparent !important;
}

.greyBorder {
    border: 1px solid #f5f5f5;
}

.darkBorder {
    border: 1px solid #888888;
}

.check-icon-color{
    color: #83479b;
}

.imageContainer {
    position: relative;
}

.imageDescription {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.7s all cubic-bezier(1, 0.9, 0, 0.09);
    background-color: rgba(255, 255, 255, 0.6);
    color: #000000;
}

//make description visible on hover
.imageContainer:hover {
    .imageDescription {
        opacity: 1;
    }
}

//position text on overlay
.imageDescription div {
    font-size: 1.125rem;
    text-decoration: none !important;
    font-weight: 400;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.catalog-cursor{
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .cardBox {
        height: 574px;
    }
}

@media (min-width: 992px) {
    .cardBox {
        height: 417px;
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    .catalogueBox {
        height: 34px;
    }
}

@media (min-width: 1200px) {
    .catalogueBox {
        height: 40px;
    }
}

@media (min-width: 1200px) {
    .catalogueSolBox {
        height: 175px;
    }

    .catalogueSolBoxEn {
        height: 115px;
    }
}


@media (min-width: 768px) and (max-width: 1199.98px) {
    .catalogueSolBox {
        height: 210px;
    }

    .catalogueSolBoxEn {
        height: 130px;
    }
}

@media (max-width: 991.98px) {

    .webp .backCatalogue, .backCatalogueRetail, .backCatalogueB2B, .backCatalogueCorporate, .backCatalogueMedia {
        background-image: url("/images/backgroundGray.webp");
        height: auto;
        padding-bottom: 3rem;
    }

    .no-webp .backCatalogue, .backCatalogueRetail, .backCatalogueB2B, .backCatalogueCorporate, .backCatalogueMedia {
        background-image: url("/images/backgroundGray.jpg");
        height: auto;
        padding-bottom: 3rem;
    }
}

