table {
    border-bottom: 1px solid grey;
}

thead {
    background-color: #212529;
    color: white;
}

td {
    vertical-align: middle !important;
   
}

td, th {
    border-left: 1px solid white;
}

.th-text-style {
    font-size: 0.9375rem;
    color: white;
    font-family: 'Raleway';
}

.td-text-style {
    font-size: 0.9375rem;
    color: #888888;
    font-family: 'Raleway';
}

.h1-cookie-text-style {
    font-size: 1.5rem;
    color: #444444;
    font-weight: 600;
    margin-bottom: 1.25rem;
    font-family: 'Raleway';
}

.h3-cookie-test-style {
    font-size: 1.5625rem;
    color: #444444;
    font-family: 'Raleway';
}

.backLightGrey {
    background-color: #f9f9f9;
}