.footer-background{
    background-color: #22262b;
}

.text-color-white {
    color: #fff !important;
}

.sub-text-color {
    color: #bbb !important;
}

.title-border {
    border-bottom: 1px solid #444444 !important;
    padding-bottom: 0.5rem;
}

.text-style {
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-size: 1.0625rem;
}

.text-style-trademark {
    font-size: 0.875rem;
}

.link-decoration-footer:hover,.link-decoration-footer:focus {
    color: #c00c00 !important;
    text-decoration: none;
    outline: none;
    transition: 0.7s;
    cursor: pointer;
}

.link-decoration-footer-policy:hover,.link-decoration-footer-policy:focus {
    color: #fff !important;
    text-decoration: none;
    outline: none;
    transition: 0.7s;
}


