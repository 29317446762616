html {
    scroll-behavior: smooth !important;
}

body{
    transition: all 300ms linear 0ms !important;
    font-family: "Source Sans Pro", sans-serif;
    color: #888;
}

.title-line, .title-line-left {
    position: relative;
    padding-bottom: 20px;
}

.about-title-line {
    padding-bottom: 10px;
}

.line-bottom .title-line::after, .line-bottom .title-line-left::after, .line-bottom .about-title-line::after {
    width: 30px;
    border-top: 4px solid #1e396b !important;
    position: absolute;
    content: "";
    margin: auto;
    padding-top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    top: inherit;
}

.line-bottom .title-line-left::after {
    right: auto;
}

.custom-button {
    background-color: $button-color;
    color: #fff !important;
    border-color: $button-color;
}

.custom-button:hover {
    background-color: black !important;
    opacity: 1;
    border-color: #000000 !important;
}

.custom-button-padding {
    padding: 0.5rem 2rem;
}

.custom-button-white {
    background-color: #fff;
    color: #000000 !important;
    border-color: #ccc;
}

.custom-button-white:hover {
    background-color: black !important;
    opacity: 1;
    color: #fff !important;
}

.custom-button-white-padding {
    padding: 0.5rem 2rem;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.custom-margins{
    margin-left: -15px;
    margin-right: -15px;
}

.font-style {
    font-family: "Raleway";
}

//Backgrounds//
.backWhite {
    background-color: white;
}

.webp .backGrey {
    background-image: url("/images/backgroundGray.webp");
    background-position: 0 0 !important;
    background-repeat: repeat !important;
}

.no-webp .backGrey {
    background-image: url("/images/backgroundGray.jpg");
    background-position: 0 0 !important;
    background-repeat: repeat !important;
}

.webp .backGreyCenter {
    background-image: url("/images/backgroundGray.webp");
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.no-webp .backGreyCenter {
    background-image: url("/images/backgroundGray.jpg");
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.webp .backPartner {
    background-image: url("/images/become-a-partnerBackground.webp");
    height: calc(100vh - 100px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.no-webp .backPartner {
    background-image: url("/images/become-a-partnerBackground.jpg");
    height: calc(100vh - 100px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 991.98px) {
    .webp .backPartner {
        background-image: url("/images/backgroundGray.webp");
        height: auto;
        padding-bottom: 3rem;
    }

    .no-webp .backPartner {
        background-image: url("/images/backgroundGray.jpg");
        height: auto;
        padding-bottom: 3rem;
    }
}

.custom-button {
    background-color: $button-color;
    color: #fff !important;
    border-color: $button-color;
}

.custom-button:hover {
    background-color: black !important;
    opacity: 1;
    border-color: #000000 !important;
}

.custom-button-padding {
    padding: 0.5rem 2rem;
}

.custom-button-white {
    background-color: #fff;
    color: #000000 !important;
    border-color: #ccc;
}

.custom-button-white:hover {
    background-color: black !important;
    opacity: 1;
    color: #fff !important;
}

.custom-button-transparent {
    background-color: transparent;
    color: #000000 !important;
    border-color: #ccc;
}

.custom-button-transparent:hover {
    background-color: black !important;
    opacity: 1;
    color: #fff !important;
}

.custom-button-white-padding {
    padding: 0.5rem 2rem;
}

.btnCatalogue {
    background-color: $button-color;
    color: #fff !important;
    opacity: 0.8;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 85%;
}

.btnCatalogue:hover {
    background-color: black;
    opacity: 1;
}

//h1
.heading-style-text {
    font-size: 1.75rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
}


//h2
.sub-heading-style-text {
    font-size: 1.125rem;
    color: #444444;
    font-weight: 400;
}

.page-heading-style-text{
    font-size: 1.5rem;
    font-weight: 600;
    color: #444444;
    letter-spacing: 0.035em;
}

.page-second-heading-style-text {
    font-size: 1rem;
    font-weight: 600;
    color: #444444;
    letter-spacing: 0.035em;
}

@media (max-width: 991.98px) {
    .sub-heading-style-text {
        font-size: 1.125rem !important;
    }

    .page-heading-style-text {
        font-size: 1.5rem !important;
    }
}

#back_to_top {
    display: none;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: #1e396b;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
    color: #fff;
    font-size: 22px;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    cursor: pointer;
    z-index: 10010;
}

#back_to_top.on {
    opacity: 1;
    visibility: visible;
}
//Text style
.textWhite {
    color: white;

}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(/webfonts/fa-solid-900.eot);
    src: url(/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(/webfonts/fa-solid-900.woff2) format("woff2"),url(/webfonts/fa-solid-900.woff) format("woff"),url(/webfonts/fa-solid-900.ttf) format("truetype"),url(/webfonts/fa-solid-900.svg#fontawesome) format("svg")
}

.fa,.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900
}

.fa-check:before {
    content: "\f00c"
}

.fa-check-circle:before {
    content: "\f058"
}

.fa-check-double:before {
    content: "\f560"
}

.fa-check-square:before {
    content: "\f14a"
}

.fa-plus:after{
    content:"\f067"
}

.fa-download:after{
    content:"\f019"
}

@font-face {
    font-family: 'icomoon';
    src:url('/fonts/icomoon.eot?-rdmvgc');
    src:url('/fonts/icomoon.eot?#iefix-rdmvgc') format('embedded-opentype'),
    url('/fonts/icomoon.woff?-rdmvgc') format('woff'),
    url('/fonts/icomoon.ttf?-rdmvgc') format('truetype'),
    url('/fonts/icomoon.svg?-rdmvgc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Shopping-Bag:before {
    content: "\ec5a";
}
.icon-Windows-Microsoft:before {
    content: "\edfc";
}
.icon-Building:before {
    content: "\e6fa";
}
.icon-ID-Card:before {
    content: "\e9d1";
}
.icon-Split-Horizontal2Window:before {
    content: "\ecb6";
}
.icon-Laptop-Phone:before {
    content: "\ea14";
}
.icon-Video-GameController:before {
    content: "\edcc";
}
.icon-Newspaper:before {
    content: "\eaff";
}
.icon-Open-Book:before {
    content: "\eb24";
}
.icon-Sidebar-Window:before {
    content: "\ec65";
}
.icon-Cube-Molecule2:before {
    content: "\e7ce";
}
.icon-Shopping-Cart:before {
    content: "\ec5c";
}
.icon-Facebook:before {
    content: "\e87f";
}
.icon-Navigation-LeftWindow:before {
    content: "\eaf5";
}
.icon-Assistant:before {
    content: "\e670";
}
.icon-Worker:before {
    content: "\ee12";
}
.icon-DNA-Helix:before {
    content: "\e819";
}
.icon-Money-Smiley:before {
    content: "\eac8";
}
.icon-Bird-DeliveringLetter:before {
    content: "\e6c0";
}

@font-face {
    font-family: Material-Design-Iconic-Font;
    src: url(/fonts/Material-Design-Iconic-Font.woff2?v=2.2.0) format('woff2'),url(/fonts/Material-Design-Iconic-Font.woff?v=2.2.0) format('woff'),url(/fonts/Material-Design-Iconic-Font.ttf?v=2.2.0) format('truetype')
}

.zmdi {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.zmdi-chevron-right:before {
    content: '\f2fb'
}
.zmdi-chevron-up:before {
    content: '\f2fc'
}
