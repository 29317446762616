.no-webp .backClients {
    background-image: url("~/images/Our-Clients.jpg");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
}

.webp .backClients {
    background-image: url("~/images/Our-Clients.webp");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
}

.title-secondary-text {
    width: 95%;
}

.title{
    padding-bottom: 1rem;
}

.line-bottom .title-line::after {
    bottom: -10px;
}

.no-webp .backClients-2 {
    background-image: url("~/images/background-wall.jpg");
}

.webp .backClients-2 {
    background-image: url("~/images/background-wall.webp");
}

.page-clients-heading-style-text {
    font-size: 	0.9375rem;
    color: #888 !important;
}

.page-custom-heading-style-text {
    font-size: 	0.9375rem;
    color: #888 !important;
}

.page-clients-comments-text {
    font-size: 1rem;
    font-weight: 500;
    color: #444444;
    letter-spacing: 0.035em;
}

.comment-margin{
    height: fit-content;
}

@media  (max-width: 990px) {
    .no-webp .backClients {
        background-image: url("~/images/background-wall.jpg");
        height: 100%;
        padding-top: 3rem;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .webp .backClients {
        background-image: url("~/images/background-wall.webp");
        height: 100%;
        padding-top: 3rem;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .title-secondary-text {
        width: 100%;
    }
    .title{
        font-size: 28px;
    }
    .comment-margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media (max-width: 992px){
    .comment {
        margin-top: 2rem;
    }
}

@media (max-width: 760px){
    .comment-margin {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}
