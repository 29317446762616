@font-face {
    font-family: 'Stroke-Gap-Icons';
    src: url('/fonts/Stroke-Gap-Icons.woff') format('woff');
}

.stroke-icon {
    font-family: 'Stroke-Gap-Icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.stroke-icon-Message:before {
    content: "\e6b2";
}

//h3
.h3-heading-style-text {
    font-size: 1.5rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-sub-heading-style-text {
    font-size: 1.375rem;
    color: #1e396b;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.h3-catalogue-style-text {
    font-size: 1.25rem;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;

}

.h3-box-style-text {
    font-size: 1rem;
    color: #444444;
    font-weight: 700;
    letter-spacing: 0.035em;
    font-family: "Raleway" !important;
}

.no-webp .backHome {
    background-image: url("/images/homepageBackground.jpg");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.webp .backHome {
    background-image: url("/images/homepageBackground.webp");
    height: calc(100vh - 80px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.round {
	height: 48px;
  	width: 48px;
  	background-color: cornflowerblue;
  	border-radius: 50%;
	display: inline-block;
}

.imgCatalogue {
    position: relative;
}

.imgCarousel {
    max-width: 150px;
    max-height: 150px;
}

.paddingTopHome {
    padding-top: 20%;
}

.carouselWidth {
    width: auto !important;
    position: relative;
}

.span-testimonials-icon {
    position: absolute;
    font-size: 2.125rem;
    top: 1rem;
    right: 1rem;
    color: #1e396b !important;
}

.button-over-image {
    padding: 0.2rem 2rem;
    font-size: 	1.125rem;
    font-weight: 700;
}

.arrow-over-image {
    font-weight: 700 !important;
    font-size: 	1.5rem !important;
    position: relative;
    top: 3px;
    margin-left: 0.625rem;
}

.title-comment-box {
    font-size: 1rem;
    color: #444;
    font-weight: 500;
    font-family: Raleway;
    letter-spacing: 0.035em;
    text-decoration: none !important;
}

.sub-title-comment-box {
    text-transform: uppercase;
    font-size: 	0.75rem;
    color: #888;
    font-weight: 300;
}

.logo-border-home{
    border: 1px solid ;
    max-width: 90%;
    color: #e5e5e5;
    align-self: center;
}

.h3-testimonials-text-style-company{
    font-size: 0.75rem;
    color: #444444;
    font-family: 'Raleway';
}

@media (min-width: 576px) {
    .h3Box {
        height: 60px;
    }
}

@media (max-width: 575.98px) {
    .imgCarousel {
        max-width: 130px;
        max-height: 130px;
    }
}

@media (min-width: 768px) {
    .paddingTopHome {
        padding-top: 15%;

    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .pCatalogue {
        height: 90px;
    }

    .h3Box {
        height: 40px;
    }

    .homepageBox {
        height: 180px;
    }

    .dev-title-style.line-top-md {
        padding-bottom: 0.3rem;
    }
}

@media (min-width: 992px) {
    .pCatalogue {
        height: 70px;
    }

    .homepageBox {
        height: 140px;
    }
}

@media (max-width: 991.98px) {
.webp .backHome {
        background-image: url("/images/backgroundGray.webp");
        height: auto;
        padding-bottom: 3rem;
    }

.no-webp .backHome {
        background-image: url("/images/backgroundGray.jpg");
        height: auto;
        padding-bottom: 3rem;
    }
    .button-over-image {
        padding: 0.2rem 1.5rem 0.3rem 1.5rem;
        font-size: 	1rem;
        font-weight: 700;
    }
}


