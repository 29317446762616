// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom
@import "custom";

// Demo page
@import "demo";

// Home page
@import "home";

// About page
@import "about";

// Catalogue page
@import "catalogue";

// Clients page
@import "clients";

// Header
@import "header";

// Contact page
@import "contact";

//Development
@import "development";

// Footer
@import "footer_main";
@import "footer_partial";

//404 page
@import "404";

//Newsletter page
@import "newsletter";

//features
@import "features";

@import "cookie_policy";

@import "terms_and_conditions";

@import "confidentiality_policy";

@import "testimonials";

@import "cookie-consent";

@import "features-carousel";

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

