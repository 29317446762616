.h1-confidentiality-text-style {
    font-size: 2rem;
    color: #444444;
    font-weight: 400;
    font-family: 'Raleway';
}

.h3-confidentiality-text-style {
    font-size: 1.5625rem;
    color: #444444;
    font-family: 'Raleway';
}

.h4-confidentiality-text-style {
    font-size: 1.375rem;
    color: #444444;
    font-family: 'Raleway';
}
