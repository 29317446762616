.cookie-consent {
    font-size: 0.9em;
    padding: 1em;
    background: #ffffff;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 30%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.cookie-consent__message {
    display: inline-block;
    color: #000000;
}

.cookie-consent__message p {
    margin: 0;
}

.cookie-consent__agree {
    font-weight: bold;
    margin: 0 1em 0 0;
    padding: .5em 1em;
    color: #ffffff;
    background-color: #0eaedf;
    border: 1px solid #0eaedf;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(217, 142, 0, 0.15);
}

.link_policy {
    color: #0eaedf;
    text-decoration: none;
}

.cookie-consent__agree:hover {
    background-color: #000000;
    color: #ffffff;
}

.cookie-consent__agree:active {
    top: 1px;
}

.cookie-consent__link {
    margin-top: auto;
    margin-bottom: auto;
}
@media (max-width: 992px) {

    .cookie-consent{
        width: 100%;
        margin-bottom: 0;
        margin-left: 0;
    }
}
@media (max-width: 768px) {
    .cookie-consent__link {
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
    .cookie-buttons {
        justify-content: center;
    }

    .cookie-consent__agree {
        margin: 0 0.5em;
    }
}
